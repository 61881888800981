<template>
<div class="mt-8 mx-8" >
  <v-data-table
    :headers="headers"
    :items="samples"
    sort-by="name"
    item-key="id"
    class="elevation-0"
    @click:row="clickRow"
    :hide-default-footer="true"
    :disable-pagination="true"
  >
      <template v-slot:item.trioData="{ item }">
        <v-simple-checkbox
          v-model="item.trioData"
          disabled
        ></v-simple-checkbox>
      </template>

    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>PROJECTS </v-toolbar-title>
        <!-- <v-divider class="mx-4" inset vertical ></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"  >
              <v-icon class="pr-2">mdi-database-plus</v-icon>New Project
            </v-btn>
          </template>
           <v-form ref="form" v-model="valid" lazy-validation >

          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col  >
                    <v-text-field  :rules="[rules.required]" validate-on-blur v-model="selectedProject.name" label="Name" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col  >
                    <v-select v-model="selectedProject.disease" validate-on-blur :rules="[rules.required]" :items="diseases" item-text="name" item-value="id" label="Disease"    ></v-select>
                  </v-col>
                  <v-col  >
                    <v-select v-model="selectedProject.technology" validate-on-blur  :rules="[rules.required]" :items="sampleTechnologies" item-text="name" item-value="id" label="Technology"    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn color="primary darken-1"  @click="save" >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">Delete Database </span>
            </v-card-title>
            <v-card-text class="body-1 mt-6 ">Are you sure you want to delete <b>{{selectedProject.name}}</b> database?</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip top color="secondary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="secondary"
              @click.stop="editItem(item)"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-database-edit-outline</v-icon>
            </v-btn>
        </template>
        <span> Edit {{item.name}}</span>
    </v-tooltip>
      <v-tooltip top color="secondary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="secondary"
              @click.stop="deleteItem(item)"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-delete-forever-outline</v-icon>
            </v-btn>

        </template>
        <span>Delete {{item.name}}</span>
      </v-tooltip>

      <!-- <v-tooltip top color="secondary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon large
              color="secondary"
              @click="clearCache(item)"
              v-on="on"
            >
            <v-icon class="mr-2">mdi-cached</v-icon>
            </v-btn>

        </template>
        <span>Clear cached Cohort Analysis Result of {{item.name}}</span>
      </v-tooltip> -->

    </template>
      <!-- <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <SampleDatasetList :sampleId="item.id" :key="item.id"></SampleDatasetList>
        </td>
      </template> -->
    <template v-slot:no-data>
        <span>No-data</span>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import { getProjects,updateProject, addProject,deleteProject, clearCache } from '@/api/project.js'
  import { getSampleStatuses,getSampleTechnologies } from '@/api/sample.js'
  import {getDiseases } from '@/api/disease.js'
  // import SampleDatasetList from '@/components/SampleDatasetList'

  export default {
    components:{
      
    },
    data: () => ({
        expanded: [],

      valid: true,
      sampleStatuses: [],
      sampleTechnologies: [],
      diseases:[],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'NAME', value: 'name' ,align: 'start',class:"accent h2"},
        // { text: 'FAMILY ID', value: 'familyId' ,class:"accent h2"},
        // { text: 'INDIVIDUAL', value: 'individual' ,class:"accent"},
        // { text: 'SEX', value: 'sex',class:"accent" },
        // { text: 'TRIO DATA', value: 'trioData' ,class:"accent"},
        // { text: 'FATHER', value: 'father' ,class:"accent"},
        // { text: 'MOTHER', value: 'mother' ,class:"accent"},
        // { text: 'STATUS', value: 'sampleStatus.name' ,class:"accent"},
        { text: 'DISEASE', value: 'disease.name' ,class:"accent"},
        { text: 'TECHNOLOGY', value: 'technology.name' ,class:"accent", divider: true},
        { text: 'ACTIONS', value: 'actions', sortable: false ,class:"accent", width:"205px", align:"center", divider: true},
        // { text: 'DATA', value: 'data-table-expand',class:"accent pl-0", cellClass:"secondary--text", align:"right",width:"40px" },

      ],
      samples: [],
      isEditing: false,
      // sexes: [{id:'M', name:'Male'}, {id:'F', name:'Female'}],
      selectedProject: {
        name: null,
        familyId:'-',
        individual:'-',
        sex: 'F',
        trioData: false,
        father:'-',
        mother:'-',
        sampleStatus: 1,
        technology: null,
        disease: null        
        
      },
      rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 100 || 'Max 100 characters'
      }
    }),

    computed: {
      formTitle () {
        return ! this.isEditing  ? 'New Project' : 'Edit ' + (this.selectedProject.name ?? 'Project')
      },
      defaultItem()
      { 
        return {
        name: null,
        familyId:'-',
        individual:'-',
        sex: 'F',
        trioData: false,
        father:'-',
        mother:'-',
        sampleStatus: 1,
        technology: null,
        disease: null,
      }
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      clickRow(item){
        this.$router.push({ name: "ProjectInfo", params: { id: item.id } });

        console.log(item)
      },
      clearCache(item){
        console.log('creale cache', item)
        clearCache(item.id, `Cached analysis result of ${item.name} cleared`)
      },
      loadProjects(){
        const _this = this
        getProjects(function(res){
          _this.samples = res
        })
      },
      initialize () {
        const _this = this
        getSampleStatuses(function(res){
          _this.sampleStatuses = res
        })
        getSampleTechnologies(function(res){
          _this.sampleTechnologies = res
        })
        getDiseases((res)=>{_this.diseases = res})
        this.loadProjects()
      },

      editItem (item) {
        this.valid = true
        this.selectedProject = JSON.parse(JSON.stringify(item))
        this.selectedProject.sampleStatus = 1 //this.sampleStatuses[0].id// this.selectedProject.sampleStatus.id
        this.selectedProject.technology = this.selectedProject.technology.id
        this.selectedProject.disease = this.selectedProject.disease?.id || null
        console.log(this.selectedProject)
        
        this.dialog = true

        this.isEditing = true
      },

      deleteItem (item) {
        this.selectedProject = JSON.parse(JSON.stringify(item))
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        const _this = this
        deleteProject(this.selectedProject.id,function(){
          // console.log(res)
          _this.loadProjects()
        }, 'Database ' + _this.selectedProject.name + " removed successfully" )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.valid = true
        // console.log('DFLKALKSJD')
        this.$nextTick(() => {
          this.selectedProject = Object.assign({}, this.defaultItem)
          this.isEditing = false
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.selectedProject = Object.assign({}, this.defaultItem)
          this.isEditing = false
        })
      },

      save () {
        var isValidated = this.$refs.form.validate()
        if (isValidated){
          if (this.isEditing ) {
            const _this = this
            updateProject(this.selectedProject, this.selectedProject.id, function(){
              // console.log(res)
              _this.loadProjects()
            }, 'Project ' + _this.selectedProject.name +  ' updated successfully'
            )
          } else {
            const _this = this
            addProject(this.selectedProject, function(){
              // console.log(res)
              _this.loadProjects()
            }, 'Project ' + _this.selectedProject.name +  ' added successfully'
            )
          }
        this.close()
        } 
      },
    },
  }
</script>
<style scoped>
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content):hover {
  cursor: pointer;
}
</style>